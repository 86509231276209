var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';
import { paths } from 'Constants/routes';
import SignIn from 'Components/auth/SignIn';
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n\n  @media only screen and (max-width: 768px) {\n    height: 100vh;\n  }\n"], ["\n  height: 100vh;\n\n  @media only screen and (max-width: 768px) {\n    height: 100vh;\n  }\n"])));
var SignInContainer = function () {
    var dispatch = useDispatch();
    return (_jsx(StyledContainer, { children: _jsx(Switch, { children: _jsx(Route, { exact: true, path: paths.auth_signin, component: SignIn }) }) }));
};
export default SignInContainer;
var templateObject_1;
