import appStorage from './local-storage';
import axios from 'axios';
var CancelToken = axios.CancelToken;
var source = CancelToken.source();
var HttpService = /** @class */ (function () {
    function HttpService(baseURL) {
        this.service = axios.create({
            baseURL: baseURL,
        });
        this.interceptor();
    }
    HttpService.prototype.interceptor = function () {
        this.service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service.interceptors.request.use(function (config) {
            config.headers = {
                Authorization: "Bearer ".concat(appStorage.getItem('token')),
                'Content-Type': 'application/json',
            };
            return config;
        }, function (error) { return Promise.reject(error); });
    };
    HttpService.prototype.handleSuccess = function (response) {
        return response.data;
    };
    HttpService.prototype.handleError = function (error) {
        var _a, _b;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 || ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 403) {
            appStorage.deleteItem('token');
        }
        return Promise.reject(error);
    };
    HttpService.prototype.get = function (endpoint, queryParams) {
        return this.service.get(endpoint, { params: queryParams });
    };
    HttpService.prototype.post = function (endpoint, data, hasCancelToken) {
        return this.service.post(endpoint, data, hasCancelToken ? { cancelToken: source.token } : {});
    };
    HttpService.prototype.put = function (endpoint, data) {
        return this.service.put(endpoint, data);
    };
    HttpService.prototype.patch = function (endpoint, data) {
        return this.service.patch(endpoint, data);
    };
    HttpService.prototype.delete = function (endpoint, data) {
        return this.service.delete(endpoint, { data: data });
    };
    return HttpService;
}());
var newCancelToken = function () {
    source = CancelToken.source();
};
var http = new HttpService(process.env.REACT_APP_BASE_URL);
export { HttpService, source, newCancelToken };
export default http;
