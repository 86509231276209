import { nanoid } from 'nanoid';
import DashboardContainer from 'Routes/dashboard/DashboardContainer';
import SignInContainer from 'Routes/sign-in/SignInContainer';
import EventContainer from 'Routes/events/EventContainer';
import DeviceContainer from 'Routes/devices/DeviceContainer';
import SettingsContainer from 'Routes/settings/SettingsContainer';
// Configuration Note: paths is using "_" to dileanate two words.
export var paths = {
    home: '/dashboards',
    auth_signin: '/sign-in',
    settings: '/settings',
    event_management: '/event_management',
    device_management: '/device_management'
};
export var routes = [
    {
        path: paths.home,
        exact: true,
        component: DashboardContainer,
        isPrivate: true,
        uuid: nanoid(),
        rolePermissions: [],
    },
    {
        path: paths.auth_signin,
        exact: true,
        component: SignInContainer,
        isPrivate: true,
        uuid: nanoid(),
        rolePermissions: [],
    },
    {
        path: paths.event_management,
        exact: true,
        component: EventContainer,
        isPrivate: true,
        uuid: nanoid(),
        rolePermissions: [],
    },
    {
        path: paths.device_management,
        exact: true,
        component: DeviceContainer,
        isPrivate: true,
        uuid: nanoid(),
        rolePermissions: [],
    },
    {
        path: paths.settings,
        exact: true,
        component: SettingsContainer,
        isPrivate: true,
        uuid: nanoid(),
        rolePermissions: [],
    },
];
