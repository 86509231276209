var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
var TextInputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 15px;\n"], ["\n  width: 100%;\n  margin-bottom: 15px;\n"])));
var TextInputBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: ", ";\n  display: ", ";\n  flex-direction: ", ";\n  justify-content: ", ";\n  align-items: ", ";\n"], ["\n  margin-top: ", ";\n  display: ", ";\n  flex-direction: ", ";\n  justify-content: ", ";\n  align-items: ", ";\n"])), function (props) { return props.type === 'checkbox' && '2em'; }, function (props) { return props.type === 'checkbox' && 'flex'; }, function (props) { return props.type === 'checkbox' && 'row-reverse'; }, function (props) { return props.type === 'checkbox' && 'center'; }, function (props) { return props.type === 'checkbox' && 'center'; });
var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 0.5em;\n  color: ", " !important;\n  // text-transform: uppercase;\n"], ["\n  display: block;\n  margin-bottom: 0.5em;\n  color: ", " !important;\n  // text-transform: uppercase;\n"])), function (props) {
    return props.type !== 'checkbox' && props.error && props.theme.color.errorColor;
});
var InputWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  background: ", ";\n  border: 1px solid\n    ", ";\n  transition: ", ";\n  border: ", ";\n  border-radius: 4px;\n\n  &:hover {\n    border-color: ", ";\n    transition: ", ";\n  }\n\n  &:focus-within,\n  :active {\n    border-color: ", ";\n    transition: ", ";\n  }\n"], ["\n  position: relative;\n  background: ", ";\n  border: 1px solid\n    ", ";\n  transition: ", ";\n  border: ", ";\n  border-radius: 4px;\n\n  &:hover {\n    border-color: ", ";\n    transition: ", ";\n  }\n\n  &:focus-within,\n  :active {\n    border-color: ", ";\n    transition: ", ";\n  }\n"])), function (props) { return props.theme.color.whiteColor; }, function (props) { return (props.error ? props.theme.color.errorColor : props.theme.color.dividerColor); }, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.type === 'checkbox' && 'none'; }, function (props) {
    return props.error ? props.theme.color.errorColor : props.theme.color.disabledColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.error ? props.theme.color.errorColor : props.theme.color.primaryColor;
}, function (props) { return props.theme.transition.transitionFast; });
var Input = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  cursor: ", ";\n  outline: none;\n  border: none;\n  padding: 0.7em 1em;\n  border-radius: 4px;\n"], ["\n  width: ", ";\n  height: ", ";\n  cursor: ", ";\n  outline: none;\n  border: none;\n  padding: 0.7em 1em;\n  border-radius: 4px;\n"])), function (props) { return (props.type === 'checkbox' ? '15px' : '100%'); }, function (props) {
    return props.type === 'checkbox' ? '15px' : props.type === 'dataset_notes' ? '100px' : '40px';
}, function (props) { return props.type === 'checkbox' && 'pointer'; });
var TextArea = styled.textarea(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 420px;\n  width: 420px;\n  height: ", ";\n  cursor: ", ";\n  outline: none;\n  border: none;\n  padding: 0.7em 1em;\n  border-radius: 4px;\n"], ["\n  max-width: 420px;\n  width: 420px;\n  height: ", ";\n  cursor: ", ";\n  outline: none;\n  border: none;\n  padding: 0.7em 1em;\n  border-radius: 4px;\n"])), function (props) {
    return props.type === 'checkbox' ? '15px' : props.type === 'dataset_notes' ? '100px' : '40px';
}, function (props) { return props.type === 'checkbox' && 'pointer'; });
var InputIcon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding: 0.7em 1em;\n  background: inherit;\n  border-radius: 4px;\n  border: none;\n  outline: none;\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    color: ", ";\n    cursor: pointer;\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding: 0.7em 1em;\n  background: inherit;\n  border-radius: 4px;\n  border: none;\n  outline: none;\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    color: ", ";\n    cursor: pointer;\n  }\n"])), function (props) { return props.theme.color.disabledColor; }, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.color.primaryColor; });
var Errormsg = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 0.5em;\n  color: ", " !important;\n  text-align: ", ";\n  height: 1.5em;\n"], ["\n  margin-top: 0.5em;\n  color: ", " !important;\n  text-align: ", ";\n  height: 1.5em;\n"])), function (props) { return props.theme.color.errorColor; }, function (props) { return props.type === 'checkbox' && 'center'; });
var TextInput = function (_a) {
    var label = _a.label, id = _a.id, type = _a.type, name = _a.name, placeholder = _a.placeholder, touched = _a.touched, error = _a.error, errorMsg = _a.errorMsg, handleChange = _a.handleChange, value = _a.value, autoComplete = _a.autoComplete, disabled = _a.disabled, isTextArea = _a.isTextArea;
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var togglePassword = function () {
        setShowPassword(function (prev) { return !prev; });
    };
    return (_jsxs(TextInputWrapper, { children: [_jsxs(TextInputBody, __assign({ type: type }, { children: [label && (_jsxs(Label, __assign({ className: "text-body-small", htmlFor: id, error: error && touched, type: type }, { children: ["\u00A0", label, type === 'checkbox' && (_jsx("a", __assign({ href: "https://www.urbansdk.com/legal/terms", target: "_blank", rel: "noreferrer" }, { children: "Terms & Conditions" })))] }))), _jsxs(InputWrapper, __assign({ className: "text-body", type: type, error: error && touched }, { children: [isTextArea ? (_jsx(TextArea, { type: 'text', name: name, placeholder: placeholder, autoComplete: autoComplete, error: error && touched, onChange: handleChange, value: value !== null && value !== void 0 ? value : '', disabled: disabled })) : (_jsx(Input, { type: type === 'search'
                                    ? 'text'
                                    : type === 'filter'
                                        ? 'number'
                                        : type !== 'password'
                                            ? type
                                            : showPassword
                                                ? 'text'
                                                : type, name: name, placeholder: placeholder, autoComplete: autoComplete, error: error && touched, onChange: handleChange, value: value !== null && value !== void 0 ? value : '', disabled: disabled })), type === 'password' && (_jsx(InputIcon, __assign({ onClick: togglePassword }, { children: _jsx(Icon, { width: "24", height: "24", viewBox: "0 0 24 24", icon: showPassword ? 'HidePasswordIcon' : 'ShowPasswordIcon' }) }))), type === 'dataset_name' && (_jsx(InputIcon, __assign({ onClick: togglePassword }, { children: _jsx(Icon, { width: "24", height: "24", viewBox: "0 0 24 24", icon: 'LocationIcon' }) }))), type === 'dataset_notes' && (_jsx(InputIcon, __assign({ onClick: togglePassword }, { children: _jsx(Icon, { width: "24", height: "24", viewBox: "0 0 24 24", icon: 'CategoryIcon' }) })))] }))] })), type !== 'filter' && error && touched && errorMsg && (_jsx(Errormsg, __assign({ className: "text-body-small", type: type }, { children: errorMsg })))] }));
};
export default TextInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
