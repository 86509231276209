var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import Layout from 'Blocks/Layout';
import useIsPublic from 'Hooks/useIsPublic';
import { AppContextProvider } from 'Context/AppContext';
import AuthProvider from 'Context/AuthContext';
import SideNav from 'Components/layouts/navs/SideNav/SideNav';
var StyledChild = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: unset;\n  height: 100%;\n  transition: 250ms;\n  overflow: hidden;\n  @media only screen and (max-width: 768px) {\n    margin-left: 0px;\n  }\n"], ["\n  margin-left: unset;\n  height: 100%;\n  transition: 250ms;\n  overflow: hidden;\n  @media only screen and (max-width: 768px) {\n    margin-left: 0px;\n  }\n"])));
export var axiosOptions = {
    failuresOnly: false,
    captureWhen: undefined,
    ignoreHeaders: false,
    sessionTokenHeader: undefined,
    sanitiser: undefined,
};
export var reduxOptions = {
    actionFilter: function (action) { return true; },
    actionTransformer: function (action) { return action; },
    actionType: function (action) { return action.type; },
    stateTransformer: function (state) {
        var jwt = state.jwt, _state = __rest(state, ["jwt"]);
        return _state;
    },
};
var AppLayout = function (_a) {
    var _b, _c, _d, _e;
    var theme = _a.theme, style = _a.style, className = _a.className, children = _a.children;
    var isPublic = useIsPublic();
    var _f = useState('70px'), navMargin = _f[0], setNavMargin = _f[1];
    var _g = useState(false), fullSidebar = _g[0], setFullSidebar = _g[1];
    var setMarginCallback = function (payload) {
        setNavMargin(payload);
    };
    var path = (_e = (_d = (_c = (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.pathname) === null || _c === void 0 ? void 0 : _c.split('/')) === null || _d === void 0 ? void 0 : _d[1]) !== null && _e !== void 0 ? _e : '';
    switch (theme) {
        case 'general':
            return (_jsx(AppContextProvider, { children: _jsx(AuthProvider, { children: _jsx(Layout.Main, __assign({ style: { overflow: 'hidden' } }, { children: _jsxs(_Fragment, { children: [path !== 'sign-in' && (_jsx(SideNav, { setMarginCallback: setMarginCallback, fullSidebar: fullSidebar, setFullSidebar: setFullSidebar })), _jsx(StyledChild, { children: children })] }) })) }) }));
        default:
            return (_jsx(Layout.Main, __assign({ style: { overflow: 'hidden' } }, { children: _jsx(_Fragment, { children: children }) })));
    }
};
export default AppLayout;
var templateObject_1;
