var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Link } from 'react-router-dom';
export var SideNavItem = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  display: flex;\n  outline: none;\n  border: none;\n  margin-top: 15px;\n  background: transparent;\n  cursor: pointer;\n  color: #9398a1;\n  padding: 0px;\n\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n\n  &:hover {\n    opacity: 0.4;\n  }\n"], ["\n  height: 40px;\n  display: flex;\n  outline: none;\n  border: none;\n  margin-top: 15px;\n  background: transparent;\n  cursor: pointer;\n  color: #9398a1;\n  padding: 0px;\n\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n\n  &:hover {\n    opacity: 0.4;\n  }\n"])));
export var StyledMenuItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  height: 40px;\n  background: transparent;\n  justify-content: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n"], ["\n  width: ", ";\n  height: 40px;\n  background: transparent;\n  justify-content: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n"])), function (props) { return (props.fullSidebar ? '238px' : '40px'); }, function (props) { return (props.fullSidebar ? 'space-between' : 'center'); }, function (props) { return (props.fullSidebar ? '12px' : 'none'); }, function (props) { return (props.fullSidebar ? '12px' : 'none'); });
export var StyledSingleMenuItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n  height: 40px;\n  background: transparent;\n  justify-content: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n"], ["\n  width: ", ";\n  height: 40px;\n  background: transparent;\n  justify-content: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n"])), function (props) { return (props.fullSidebar ? '238px' : '40px'); }, function (props) { return (props.fullSidebar ? 'start' : 'center'); }, function (props) { return (props.fullSidebar ? '12px' : 'none'); }, function (props) { return (props.fullSidebar ? '12px' : 'none'); });
export var DropdownLink = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n\n  &:hover {\n    opacity: 0.4;\n    cursor: pointer;\n    text-decoration: none;\n    color: #9398a1;\n  }\n"], ["\n  .active {\n    color: white;\n    background: rgba(255, 255, 255, 0.1);\n  }\n\n  &:hover {\n    opacity: 0.4;\n    cursor: pointer;\n    text-decoration: none;\n    color: #9398a1;\n  }\n"])));
export var StyledChildItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 40px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  text-decoration: none;\n  color: #9398a1;\n"], ["\n  height: 40px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  text-decoration: none;\n  color: #9398a1;\n"])));
export var SidebarLabel = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: ", ";\n  font-size: 13px;\n"], ["\n  margin-left: ", ";\n  font-size: 13px;\n"])), function (props) { return (props.withIcon ? '10px' : props.active ? '36px' : '40px'); });
export var StyledItemCount = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: inline-block;\n  text-align: center;\n  background: rgba(255, 255, 255, 0.1);\n  margin-left: 8px;\n  width: 22px;\n  line-height: 22px;\n  border-radius: 70px;\n"], ["\n  display: inline-block;\n  text-align: center;\n  background: rgba(255, 255, 255, 0.1);\n  margin-left: 8px;\n  width: 22px;\n  line-height: 22px;\n  border-radius: 70px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
