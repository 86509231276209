var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { StyledMenuItem, SideNavItem, DropdownLink, SidebarLabel, StyledItemCount, StyledChildItem, } from './nav.styled';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
var ArrowExpandItem = function () { return (_jsx("svg", __assign({ width: "10", height: "8", viewBox: "0 0 10 8", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M1.175 0.912598L5 4.72926L8.825 0.912598L10 2.0876L5 7.0876L0 2.0876L1.175 0.912598Z", fill: "#9398A1" }) }))); };
var ArrowCollapseItem = function () { return (_jsx("svg", __assign({ width: "10", height: "8", viewBox: "0 0 10 8", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M8.825 7.0874L5 3.27074L1.175 7.0874L0 5.9124L5 0.912403L10 5.9124L8.825 7.0874Z", fill: "white" }) }))); };
var SelectedIndicator = function () { return (_jsx("svg", __assign({ width: "4", height: "24", viewBox: "0 0 4 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("rect", { width: "4", height: "24", fill: "white" }) }))); };
export var ExpandedItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 4px;\n"], ["\n  background: ", ";\n  border-radius: 4px;\n"])), function (props) {
    return props.fullSidebar && props.navOpen ? 'rgba(255, 255, 255, 0.05)' : 'none';
});
var MultiLevel = function (_a) {
    var item = _a.item, fullSidebar = _a.fullSidebar, navItemProp = _a.navItemProp, setNavItemProp = _a.setNavItemProp, isItemOpen = _a.isItemOpen, quickStartIsVisible = _a.quickStartIsVisible, setShowProductSwitchModal = _a.setShowProductSwitchModal, setProductPath = _a.setProductPath, warnOnExit = _a.warnOnExit;
    var children = item.items;
    var dispatch = useDispatch();
    var handleClick = function (event) {
        if (warnOnExit) {
            event.preventDefault();
            setShowProductSwitchModal(true);
            setProductPath(item.app_path);
        }
        else {
            var clickedItemId = event.currentTarget.id;
            for (var _i = 0, navItemProp_1 = navItemProp; _i < navItemProp_1.length; _i++) {
                var navItem = navItemProp_1[_i];
                if (navItem.name === clickedItemId) {
                    navItem.open = !navItem.open;
                }
            }
            setNavItemProp(navItemProp);
        }
    };
    var handleChildClick = function (e, child) {
        if (warnOnExit) {
            e.preventDefault();
            setShowProductSwitchModal(true);
            setProductPath(child.app_path);
        }
    };
    return (_jsxs(ExpandedItemWrapper, __assign({ fullSidebar: fullSidebar, navOpen: isItemOpen }, { children: [_jsx(Link, __assign({ onClick: handleClick, id: item.name, to: item.app_path, style: { textDecoration: 'none' } }, { children: _jsx(SideNavItem, __assign({ fullSidebar: fullSidebar }, { children: _jsxs(StyledMenuItem, __assign({ className: item.active ? 'active' : 'inactive', fullSidebar: fullSidebar }, { children: [_jsxs("span", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [item.active ? item.icon_enabled : item.icon_disabled, fullSidebar ? _jsx("span", __assign({ style: { marginLeft: 10 } }, { children: item.name })) : ''] })), fullSidebar ? (_jsx("span", { children: isItemOpen ? _jsx(ArrowCollapseItem, {}) : _jsx(ArrowExpandItem, {}) })) : ('')] })) })) })), fullSidebar &&
                isItemOpen &&
                children.map(function (child, idx) {
                    return (_jsx(DropdownLink, __assign({ onClick: function (e) { return handleChildClick(e, child); }, to: child.app_path }, { children: _jsxs(StyledChildItem, __assign({ className: child.active ? 'active' : 'inactive' }, { children: [child.active && _jsx(SelectedIndicator, {}), item.active ? item.icon_enabled : item.icon_disabled, _jsx(SidebarLabel, __assign({ withIcon: !_.isEmpty(item.icon), active: child.active }, { children: child.name })), !_.isEmpty(child.item_count) ? (_jsx(StyledItemCount, { children: child.item_count })) : ('')] })) }), idx));
                })] })));
};
export default MultiLevel;
var templateObject_1;
