var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext } from 'react';
var AppContext = createContext({});
var AppContextProvider = function (_a) {
    var _b = _a.children, children = _b === void 0 ? _jsx(_Fragment, {}) : _b;
    var _c = useState(false), isDeleting = _c[0], setIsDeleting = _c[1];
    return (_jsx(AppContext.Provider, __assign({ value: {
            setIsDeleting: setIsDeleting,
            isDeleting: isDeleting,
        } }, { children: children })));
};
export { AppContext, AppContextProvider };
