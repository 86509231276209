var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appStorage from 'Helpers/local-storage';
import { paths } from 'Constants/routes';
import _ from 'lodash';
var AuthContext = createContext({
    token: null,
    setToken: function () { },
    logout: function () { },
    setUserInfo: function () { },
    userInfo: null
});
var AuthProvider = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState(), userInfo = _b[0], setUserInfo = _b[1];
    var _c = useState(appStorage.getItem('token')), token = _c[0], setToken_ = _c[1];
    var setToken = function (newToken) {
        setToken_(newToken);
    };
    var logout = function () {
        setToken(null);
    };
    useEffect(function () {
        if (token) {
            if (_.isEmpty(appStorage.getItem('token'))) {
                history.push(paths.home);
            }
            appStorage.saveItem('token', token);
        }
        else {
            appStorage.deleteItem('token');
        }
    }, [token]);
    var contextValue = useMemo(function () { return ({
        token: token,
        setToken: setToken,
        userInfo: userInfo,
        setUserInfo: setUserInfo
    }); }, [token]);
    return (_jsx(AuthContext.Provider, __assign({ value: __assign(__assign({}, contextValue), { userInfo: userInfo, setUserInfo: setUserInfo, logout: logout, token: token }) }, { children: children })));
};
export var useAuth = function () {
    return useContext(AuthContext);
};
export default AuthProvider;
