var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from 'Context/AuthContext';
import { loginApi } from 'API/auth-apis';
import TextInput from 'Components/common/TextInput';
import TextButton from 'Components/common/TextButton';
var initialValues = {
    email_address: '',
    password: '',
};
var validationSchema = Yup.object().shape({
    email_address: Yup.string().required('Required Field').email('Invalid Email'),
    password: Yup.string()
        .required('Required Field')
        .min(8, 'Must be at least 8 characters')
        .max(64, 'Must be less than 64 characters')
        .matches(/^.*[A-Z].*$/, 'Must include at least 1 Uppercase')
        .matches(/^.*[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?].*$/, 'Must include at least 1 special character')
        .matches(/\d/, 'Must include at least 1 digit'),
});
var Login = function () {
    var _a, _b;
    var _c = useAuth(), setToken = _c.setToken, setUserInfo = _c.setUserInfo;
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState(), error = _e[0], setError = _e[1];
    var formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var payload, response, error_1;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        payload = {
                            email_address: values.email_address,
                            password: values.password,
                        };
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, 4, 5]);
                        setIsLoading(true);
                        return [4 /*yield*/, loginApi(payload)];
                    case 2:
                        response = _d.sent();
                        setUserInfo(response.user);
                        setToken(response.user.id);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _d.sent();
                        setError((_c = (_b = (_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Email or password doesn't match");
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "form__header" }, { children: _jsx("h2", __assign({ className: "form__header__title" }, { children: "Welcome back. Please sign in" })) })), _jsxs("form", __assign({ className: "form__body", onSubmit: formik.handleSubmit }, { children: [_jsx(TextInput, { type: "email", id: "email_address", name: "email_address", placeholder: "Email", touched: !!formik.touched.email_address, error: !!formik.errors.email_address, errorMsg: (_a = formik.errors.email_address) !== null && _a !== void 0 ? _a : '', handleChange: formik.handleChange, value: formik.values.email_address, autoComplete: "off" }), _jsx(TextInput, { type: "password", id: "password", name: "password", placeholder: "Password", touched: !!formik.touched.password, error: !!formik.errors.password, errorMsg: (_b = formik.errors.password) !== null && _b !== void 0 ? _b : '', handleChange: formik.handleChange, value: formik.values.password, autoComplete: "off" }), _jsxs("div", __assign({ className: "form__body__footer" }, { children: [_jsx("div", __assign({ className: "text-body-small form__body__footer__message ".concat(error && 'form__body__footer__message--failed') }, { children: error })), _jsx(TextButton, { type: "submit", buttonTheme: "primary", text: "Sign in", loading: isLoading, disabled: isLoading })] }))] }))] }));
};
export default Login;
