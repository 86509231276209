var color = {
    headlineColor: '#273142',
    secondaryColor: '#8591A4',
    disabledColor: '#ACBECB',
    lightColor: '#D5DEE5',
    dividerColor: '#EAEEF2',
    backgroundColor: '#F9FAFB',
    whiteColor: '#FFFFFF',
    yellowColor: '#EDC358',
    purpleColor: '#6C63FF',
    purpleHoverColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #6C63FF',
    contrastColor: '#3D4655',
    primaryColor: '#1991EB',
    primaryHoverColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1991EB',
    primaryActiveColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #1991EB;',
    focusedOutlineColor: 'rgba(25, 145, 235, 0.2);',
    transparentColor: 'rgba(255, 255, 255, 0.2)',
    transparentHoverColor: 'rgba(255, 255, 255, 0.3)',
    transparentActiveColor: 'rgba(255, 255, 255, 0.4)',
    successColor: '#39B54A',
    errorColor: '#FE4848',
    attentionColor: '#F7981C',
};
var font = {
    regular: 'SF-Pro-Text-Regular',
    medium: 'SF-Pro-Text-Medium',
    semi_bold: 'SF-Pro-Text-Semibold',
};
var transition = {
    transitionFast: 'all .2s ease',
    transitionNormal: 'all .4s ease',
    transitionSlow: 'all .8s ease',
};
var breakPoints = {
    desktop: '1200px',
    laptop: '950px',
    tablet: '768px',
    mobile: '400px',
};
export var theme = {
    default: {
        name: 'default',
        color: color,
        font: font,
        transition: transition,
        breakPoints: breakPoints,
    },
};
