var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import NotFound from 'Components/common/NotFound';
import { PrivateRoute } from '../hocs';
import { routes } from '../constants/routes';
import { paths } from 'Constants/routes';
var Routes = function () {
    var dispatch = useDispatch();
    var _a = useState(true), visibleRootSplash = _a[0], setRootVisibleSplash = _a[1];
    return (_jsx(Suspense, __assign({ fallback: _jsx(_Fragment, {}) }, { children: _jsxs(Switch, { children: [routes.map(function (_a) {
                    var isPrivate = _a.isPrivate, _b = _a.rolePermissions, rolePermissions = _b === void 0 ? [] : _b, uuid = _a.uuid, rest = __rest(_a, ["isPrivate", "rolePermissions", "uuid"]);
                    var path = __assign({}, rest);
                    var routePath = path.path;
                    var routeAvailableToPublic = _.isEmpty(rolePermissions);
                    if (routeAvailableToPublic) {
                        if (!isPrivate) {
                            return _jsx(Route, __assign({}, rest), uuid);
                        }
                        return _jsx(PrivateRoute, __assign({}, rest), uuid);
                    }
                }), _jsx(Route, { exact: true, path: "/", render: function () { return _jsx(Redirect, { to: paths.auth_signin }); } }), _jsx(Route, { component: NotFound })] }) })));
};
export default Routes;
