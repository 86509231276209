var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import animationData from 'Components/common/loader/lottifiles/something_went_wrong.json';
import Loader from 'Components/common/loader/Loader';
var StyledNotFound = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  opacity: 1;\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: flex-start;\n  align-items: center;\n  color: ", ";\n  flex-direction: column;\n"], ["\n  background: ", ";\n  opacity: 1;\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: flex-start;\n  align-items: center;\n  color: ", ";\n  flex-direction: column;\n"])), function (props) { return props.theme.color.backgroundColor; }, function (props) { return props.theme.color.whiteColor; });
var options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};
var NotFound = function (_a) {
    var isPublic = _a.isPublic;
    var pathname = useLocation().pathname;
    var dispatch = useDispatch();
    return (_jsxs(StyledNotFound, { children: [_jsx(Loader, { width: '100%', height: '100%', options: options }), _jsx("h3", __assign({ style: { color: '#FFFFFF', textDecoration: 'underline' } }, { children: _jsx(Link, __assign({ style: { fontSize: 16 }, to: "/" }, { children: "Go Home" })) }))] }));
};
export default NotFound;
var templateObject_1;
