var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import animationData from 'Components/common/loader/lottifiles/something_went_wrong.json';
import Loader from 'Components/common/loader/Loader';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import * as Sentry from '@sentry/browser'
// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   debug: process.env.REACT_APP_ENV === 'development',
//   environment: process.env.REACT_APP_ENV,
//   release: process.env.REACT_APP_BUILD_NUM,
// })
var StyledBoundary = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: flex-start;\n  align-items: center;\n  color: ", ";\n  flex-direction: column;\n"], ["\n  background: ", ";\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: flex-start;\n  align-items: center;\n  color: ", ";\n  flex-direction: column;\n"])), function (props) { return props.theme.color.backgroundColor; }, function (props) { return props.theme.color.whiteColor; });
var options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    // public state: State = {
    //   hasError: false,
    // };
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
        // this.onClickHandler = this.onClickHandler.bind(this);
    }
    ErrorBoundary.getDerivedStateFromError = function (_) {
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // Sentry.withScope(scope => {
        //   Object.keys(errorInfo).forEach(key => {
        //     scope.setExtra(key, errorInfo[key])
        //   })
        //   Sentry.captureException(error)
        // })
    };
    // onClickHandler() {
    //   browserHistory.push('/home');
    // }
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            return (_jsxs(StyledBoundary, { children: [_jsx(Loader, { width: '100%', height: '100%', options: options }), _jsx("h3", __assign({ style: { color: '#FFFFFF', textDecoration: 'underline' } }, { children: _jsx(Link, __assign({ onClick: function () { return window.location.reload(); }, style: { fontSize: 16 }, to: "/" }, { children: "Try again" })) }))] }));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(Component));
export default ErrorBoundary;
var templateObject_1;
