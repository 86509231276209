import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
// import session, { defaultState as sessionDefaultState } from './test/reducers/index'
// ...(session as any)
var reducers = function (history) { return combineReducers({
    router: connectRouter(history),
}); };
export default reducers;
//   session: sessionDefaultState,
export var defaultState = {};
