var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import App from './App';
import './styles.css';
import store from './store';
import { Provider } from 'react-redux';
// import 'react-app-polyfill/ie9'
// import 'react-app-polyfill/stable'
// import 'react-app-polyfill/ie11'
// import 'core-js/stable'
import 'react-datepicker/dist/react-datepicker.css';
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
var allowedObservableEnvs = ['production'];
// const bootSentry = () => {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//     debug: process.env.REACT_APP_ENV === 'development',
//     environment: process.env.REACT_APP_ENV,
//     release: process.env.REACT_APP_BUILD_NUM,
//   })
// }
// if (allowedObservableEnvs.includes(process.env.REACT_APP_ENV ?? 'development')) {
//   bootSentry()
// }
var container = document.getElementById('app');
ReactDOM.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(App, {}) })), container);
