var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// import Spinner from './Spinner'
import Icon from './Icon';
var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  border-radius: 4px;\n  border: none;\n  padding: 0.7em 1.5em;\n  cursor: ", ";\n  display: inline-flex;\n  justify-content: center;\n  -webkit-box-align: center;\n  overflow: hidden;\n  align-items: center;\n  width: ", ";\n  height: 40px;\n"], ["\n  position: relative;\n  border-radius: 4px;\n  border: none;\n  padding: 0.7em 1.5em;\n  cursor: ", ";\n  display: inline-flex;\n  justify-content: center;\n  -webkit-box-align: center;\n  overflow: hidden;\n  align-items: center;\n  width: ", ";\n  height: 40px;\n"])), function (props) { return !props.disabled && 'pointer'; }, function (props) { return (props.style && props.style.width ? props.style.width : 'unset'); });
var PrimaryButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"])), function (props) {
    return props.disabled ? props.theme.color.backgroundColor : props.theme.color.primaryColor;
}, function (props) {
    return props.disabled ? props.theme.color.disabledColor : props.theme.color.whiteColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled ? props.theme.color.backgroundColor : props.theme.color.primaryHoverColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled ? props.theme.color.backgroundColor : props.theme.color.primaryActiveColor;
}, function (props) { return props.theme.transition.transitionFast; });
var SecondaryButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"])), function (props) { return props.theme.color.whiteColor; }, function (props) {
    return props.disabled ? props.theme.color.lightColor : props.theme.color.disabledColor;
}, function (props) { return props.theme.color.dividerColor; }, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled ? props.theme.color.whiteColor : props.theme.color.backgroundColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled ? props.theme.color.whiteColor : props.theme.color.dividerColor;
}, function (props) { return props.theme.transition.transitionFast; });
var DeleteButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"])), function (props) { return props.theme.color.whiteColor; }, function (props) { return (props.disabled ? props.theme.color.lightColor : props.theme.color.errorColor); }, function (props) { return props.theme.color.dividerColor; }, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled ? props.theme.color.whiteColor : props.theme.color.backgroundColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled ? props.theme.color.whiteColor : props.theme.color.dividerColor;
}, function (props) { return props.theme.transition.transitionFast; });
var TransparentButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"])), function (props) { return props.theme.color.transparentColor; }, function (props) {
    return props.disabled ? props.theme.color.disabledColor : props.theme.color.whiteColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled
        ? props.theme.color.transparentColor
        : props.theme.color.transparentHoverColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled
        ? props.theme.color.transparentColor
        : props.theme.color.transparentActiveColor;
}, function (props) { return props.theme.transition.transitionFast; });
var MapMarkerButton = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n    opacity: 1;\n    box-shadow: 0px 8px 32px rgba(39, 49, 66, 0.2);\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  transition: ", ";\n\n  &:hover {\n    background: ", ";\n    transition: ", ";\n    opacity: 1;\n    box-shadow: 0px 8px 32px rgba(39, 49, 66, 0.2);\n  }\n  &:focus {\n    transition: ", ";\n  }\n  &:active {\n    background: ", ";\n    transition: ", ";\n  }\n"])), function (props) {
    return props.className === 'active'
        ? props.theme.color.primaryColor
        : props.theme.color.transparentColor;
}, function (props) {
    return props.disabled ? props.theme.color.disabledColor : props.theme.color.whiteColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) {
    return props.disabled
        ? props.theme.color.transparentColor
        : props.theme.color.transparentHoverColor;
}, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.transition.transitionFast; }, function (props) { return props.theme.color.primaryColor; }, function (props) { return props.theme.transition.transitionFast; });
var TextButton = function (_a) {
    var type = _a.type, buttonTheme = _a.buttonTheme, text = _a.text, icon = _a.icon, loading = _a.loading, disabled = _a.disabled, onClickHandler = _a.onClickHandler, style = _a.style, className = _a.className;
    var getButtonContent = function () {
        if (loading) {
            // return <Spinner disabled={disabled} />
            return (_jsx(_Fragment, {}));
        }
        else
            return (_jsxs(_Fragment, { children: [icon && _jsx(Icon, { width: "24", height: "24", viewBox: "0 0 24 24", icon: icon, hasText: !!text }), text] }));
    };
    switch (buttonTheme) {
        case 'primary':
            return (_jsx(PrimaryButton, __assign({ style: style, disabled: disabled, type: type, hasText: !!text, onClick: onClickHandler }, { children: getButtonContent() })));
        case 'secondary':
            return (_jsx(SecondaryButton, __assign({ style: style, disabled: disabled, type: type, hasText: !!text, onClick: onClickHandler }, { children: getButtonContent() })));
        case 'delete':
            return (_jsx(DeleteButton, __assign({ disabled: disabled, type: type, hasText: !!text, onClick: onClickHandler }, { children: getButtonContent() })));
        case 'transparent':
            return (_jsx(TransparentButton, __assign({ style: style, disabled: disabled, type: type, hasText: !!text, onClick: onClickHandler }, { children: getButtonContent() })));
        case 'mapMarker':
            return (_jsx(MapMarkerButton, __assign({ style: style, className: className, disabled: disabled, type: type, hasText: !!text, onClick: onClickHandler }, { children: _jsx("div", { children: _jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C15.31 2 18 4.69 18 8C18 12.5 12 19 12 19C12 19 6 12.5 6 8C6 4.69 8.69 2 12 2ZM19 22V20H5V22H19ZM8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8C16 10.13 13.92 13.46 12 15.91C10.08 13.47 8 10.13 8 8ZM10 8C10 6.9 10.9 6 12 6C13.1 6 14 6.9 14 8C14 9.1 13.11 10 12 10C10.9 10 10 9.1 10 8Z", fill: "#D5DEE5" }) })) }) })));
        default:
            return (_jsx(PrimaryButton, __assign({ style: style, disabled: disabled, type: type, hasText: !!text, onClick: onClickHandler }, { children: getButtonContent() })));
    }
};
export default TextButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
