import logger from './logger'
import thunk from './thunk'
import routerMiddleware from './router'
import { createBrowserHistory } from 'history'
export const history = createBrowserHistory()

const middleware = [
  thunk,
  routerMiddleware(history),
]

// logger is undefined in production environments
if (process.env.NODE_ENV === `development`) {
  middleware.push(logger)
}

export default middleware
