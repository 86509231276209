import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import reducers, { defaultState } from '../modules/reducers'
import middleware, { history } from '../middleware'
import { composeWithDevTools } from 'redux-devtools-extension'

const store = createStore(
  reducers(history),
  defaultState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export { history }

export default store
