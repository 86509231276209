var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Route, Switch } from 'react-router';
import { paths } from 'Constants/routes';
import Event from 'Components/event/Event';
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n\n  @media only screen and (max-width: 768px) {\n    height: 100vh;\n  }\n"], ["\n  height: 100vh;\n\n  @media only screen and (max-width: 768px) {\n    height: 100vh;\n  }\n"])));
var EventContainer = function () {
    return (_jsx(StyledContainer, { children: _jsx(Switch, { children: _jsx(Route, { exact: true, path: paths.event_management, component: Event }) }) }));
};
export default EventContainer;
var templateObject_1;
