var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { StyledSingleMenuItem, SideNavItem, StyledItemCount } from './nav.styled';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
var SingleLevel = function (_a) {
    var item = _a.item, fullSidebar = _a.fullSidebar, quickStartIsVisible = _a.quickStartIsVisible, setShowProductSwitchModal = _a.setShowProductSwitchModal, setProductPath = _a.setProductPath, warnOnExit = _a.warnOnExit;
    var dispatch = useDispatch();
    var handleClick = function (e) {
        if (warnOnExit) {
            e.preventDefault();
            setShowProductSwitchModal(true);
            setProductPath(item.app_path);
        }
    };
    return (_jsx(Link, __assign({ to: item.app_path, onClick: handleClick, style: { textDecoration: 'none' } }, { children: _jsx(SideNavItem, __assign({ fullSidebar: fullSidebar }, { children: _jsxs(StyledSingleMenuItem, __assign({ className: item.active ? 'active' : 'inactive', fullSidebar: fullSidebar }, { children: [item.active ? item.icon_enabled : item.icon_disabled, fullSidebar ? (_jsxs("span", __assign({ style: { marginLeft: 10 } }, { children: [item.name, !_.isEmpty(item.item_count) ? (_jsx(StyledItemCount, { children: item.itemCount })) : ('')] }))) : ('')] }), item.id) })) })));
};
export default SingleLevel;
