var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { hot } from 'react-hot-loader/root';
import Modal from 'react-modal';
import AppLayout from 'Components/layouts/AppLayout';
import ErrorBoundary from 'Components/common/ErrorBoundary';
import appStorage from './helpers/local-storage';
import { history } from './store';
import { theme, GlobalStyles } from './theme';
import Routes from './routes/routes';
import 'rsuite/dist/rsuite.min.css';
Modal.setAppElement('#app');
var App = function () {
    var _a, _b, _c, _d;
    var dispatch = useDispatch();
    var token = appStorage.getItem('token');
    // const {
    //   session: { authModal },
    // } = useSelector((state: any) => state)
    var _e = useState(false), initialized = _e[0], setInitialized = _e[1];
    var router = useSelector(function (state) { return state.router; });
    var path = (_d = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname) === null || _b === void 0 ? void 0 : _b.split('/')) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : '';
    return (_jsx(Suspense, __assign({ fallback: _jsx(_Fragment, {}) }, { children: _jsxs(ThemeProvider, __assign({ theme: theme.default }, { children: [_jsx(GlobalStyles, {}), _jsx(ConnectedRouter, __assign({ history: history }, { children: _jsx(ErrorBoundary, { children: _jsx(AppLayout, __assign({ theme: "general" }, { children: _jsx(Routes, {}) })) }) }))] })) })));
};
export default hot(App);
