var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
        this.store = window.localStorage;
    }
    LocalStorage.prototype.getItem = function (key) {
        var item = this.store.getItem(key);
        return item ? JSON.parse(item) : null;
    };
    LocalStorage.prototype.saveItem = function (key, data) {
        this.store.setItem(key, JSON.stringify(data));
    };
    LocalStorage.prototype.deleteItem = function (key) {
        this.store.removeItem(key);
    };
    return LocalStorage;
}());
var appStorage = new LocalStorage();
export default appStorage;
