export var WaterLevelMockData = {
    title: 'Water Level',
    value: '8,000',
    unit: 'oz',
    series: [
        { curve: "catmullRom", data: [0, 5, 2, 6, 3, 9.3] },
        { curve: "catmullRom", data: [6, 3, 7, 9.5, 4, 2] },
    ],
    legend: ["Top Sensor", "Bottom"],
};
export var MetricCardMockData = [
    {
        title: 'Battery Level',
        value: '100%',
        description: 'Last Updated Today',
        icon: 'https://appcdn.s3.us-east-2.amazonaws.com/app-icons/battery_icon.png'
    },
    {
        title: 'Temperature',
        value: '75 F',
        description: 'Last Updated Today',
        icon: 'https://appcdn.s3.us-east-2.amazonaws.com/app-icons/temperature_icon.png'
    },
    {
        title: 'Total Water Stored',
        value: '102,000 OZ',
        description: 'Last Updated Today',
        icon: 'https://appcdn.s3.us-east-2.amazonaws.com/app-icons/water_stored_icon.png'
    },
    {
        title: 'Total Water Saved',
        value: '$900k',
        description: '+3.06% More earnings than usual',
        icon: 'https://appcdn.s3.us-east-2.amazonaws.com/app-icons/water_saved_icon.png'
    },
];
