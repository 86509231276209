import { useMemo } from 'react';
import { useSelector } from 'react-redux';
var useIsPublic = function () {
    var router = useSelector(function (state) { return state.router; });
    var isPublic = useMemo(function () {
        var _a;
        var path = (_a = router.location.pathname.split('/')[1]) !== null && _a !== void 0 ? _a : '';
        var org_slug = router.location.pathname.split('/')[2];
        return path === 'public' && org_slug;
    }, [router.location.pathname]);
    return isPublic;
};
export default useIsPublic;
