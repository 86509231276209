import { jsx as _jsx } from "react/jsx-runtime";
import Lottie from 'react-lottie';
// import animationData from './loader.json'
//pass 'options' obj into Loader for settings
//import JSON file as 'animaltionData' and set for animationData property
// const options = {
//   loop: true,
//   autoplay: true,
//   animationData: animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// }
export default function Loader(props) {
    var height = props.height, width = props.width, options = props.options;
    return (_jsx("div", { children: _jsx(Lottie, { options: options, height: height, width: width }) }));
}
