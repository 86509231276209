var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var ScoreCard = function (_a) {
    var currentChart = _a.currentChart, styles = _a.styles;
    var title = currentChart === null || currentChart === void 0 ? void 0 : currentChart.title;
    var unit = currentChart === null || currentChart === void 0 ? void 0 : currentChart.value;
    var subtitle = currentChart === null || currentChart === void 0 ? void 0 : currentChart.description;
    var icon = currentChart === null || currentChart === void 0 ? void 0 : currentChart.icon;
    return (_jsx("div", __assign({ className: "scorecard", style: __assign({ display: 'flex', flexDirection: 'column', width: '500px', height: '200px', justifyContent: 'space-between', border: '2px solid #EAEEF2', padding: '15px', borderRadius: 4, boxShadow: '0px 0px 16px 0px rgba(50, 50, 50, 0.15)', marginLeft: '10px', marginRight: '10px', marginTop: '20px', background: '#fff', "&:hover": {
                cursor: "pointer",
                boxShadow: "0px 0px 16px 0px rgba(50, 50, 50, 0.35)"
            } }, styles) }, { children: _jsx("div", __assign({ className: "chart-section" }, { children: _jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx("h1", __assign({ style: { width: '100%', fontSize: '38px', color: styles === null || styles === void 0 ? void 0 : styles.color } }, { children: title })), _jsx("p", __assign({ style: {
                                    fontSize: '22px',
                                    fontFamily: 'SF-Pro-Text-SemiBold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '50px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    width: '200px',
                                } }, { children: unit })), _jsx("img", { src: icon, alt: "icon", style: { width: 40, height: 40 } })] })), _jsx("div", __assign({ className: "title-text" }, { children: _jsx("p", __assign({ style: { fontSize: '14px' } }, { children: subtitle })) }))] })) })) })));
};
export default ScoreCard;
